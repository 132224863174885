<script setup>
import { useI18n } from 'vue-i18n';
import { computed, inject } from 'vue';

import Table from '@/Components/Table/Table.vue';
import Link from '@/Components/Button/Link.vue';

const { t } = useI18n();

const route = inject('route');
const props = defineProps({
    company: Object,
});
const tableSettings = [{ label: t('{model} locations', { model: props.company.commercial_name }) }, {}];

const tableData = computed(() =>
    props.company.locations.map((location) => {
        return {
            id: location.id,
            route: route('location.locations.edit', location.id),
            rowData: [location.nickname],
        };
    })
);
</script>

<template>
    <Table class="mb-4" :tableSettings="tableSettings" :data="tableData" />
    <Link :text="$t('Create a {model}', { model: $t('company') })" :href="route('location.companies.create')" />
</template> 